.container-add-shipment {
  height: 160px;
  .layout {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .body-row {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .footer-row {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
