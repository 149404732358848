.viewer-container {
  display: inline-block;
  padding-top: 80px;
  padding-bottom: 80px;

  .pdf-viewer {
    display: inline-block;

    .canvasWrapper {
      overflow: hidden;
    }

    .pdf-viewer__page + .pdf-viewer__page {
      margin-top: 10px;
    }

    .page {
      position: relative;

      // disable multi highlight ext for pdf annotation
      .textLayer {
        display: none;
      }

      .loadingIcon {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url('./loading-icon.gif') center no-repeat;
        background-color: #fff;
        z-index: 1050;
      }

      .pdf-viewer__shadow {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 100%;
        background: rgba(73, 77, 82, 0.8);
        cursor: not-allowed;
      }
    }
  }
}
