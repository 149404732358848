.attachment {
  .file {
    font-weight: 600;
  }

  .dropzone {
    padding: 15px;
    border: 1px dashed #009fb5;
    text-align: center;
    border-radius: 10px;

    .input {
      display: none;
    }

    .label-file {
      font-weight: 800;
      margin-bottom: 2px;
      .choose-files {
        text-decoration: underline;
        color: #009fb5;
        cursor: pointer;
        display: inline-block;
      }
    }
    .supported-files {
      margin-bottom: 2px;
      color: #aaa;
    }
    .max-file-size {
      color: #eb0045;
    }
  }

  .no-attachment {
    color: #aaaaaa;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    padding: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: default;
  }
}
