.quotation-search-result-wrapper {
  position: absolute;
  left: 0;
  top: 100%;
  width: 380px;
  display: block;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease-in-out;

  &.show-result {
    height: auto;
    visibility: visible;
    opacity: 1;
  }

  .backdrop-search-result {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 1040;
    opacity: 0.5;
  }

  ul.container-list {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 1050;
    margin-top: 10px;
    height: 90vh;
    overflow: auto;

    .quotation-item {
      background-color: #fff !important;
    }
  }
}
