$pink: #eb008b !default;
$orange: #f68e56 !default;
$red: #eb0045 !default;
$blue: #6fcfeb !default;
$darkblue: #009fb5 !default;
$black: #343a3f !default;
$dark: #4d5256 !default;
$auth_background: #60269e !default;
$loader_auth: #60269e !default;
$gradient: linear-gradient(to right, #6fcfeb, #60269e, #eb0045) !default;
$primary_workinghours: #009fb5 !default;
$gray: #e0e0e0 !default;

:root {
  --info: #6fcfeb;
  --primary: #009fb5;
  --success: #009fb5;
  --warning: #f68e56;
  --danger: #eb0045;
}

.btn-darkprimary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.bg-darkprimary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.bg-warning {
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
}
.bg-success {
  background-color: var(--success) !important;
  border-color: var(--success) !important;
}
.bg-danger {
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}

.theme-ngine {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $darkblue;
  }

  .textbox-form-container {
    .input-validation-msg {
      background-color: $red !important;
    }
  }

  .label-status {
    &.label-status-paid {
      background-color: $blue !important;
    }

    &.label-status-complete {
      background-color: $blue !important;
    }

    &.label-status-completed {
      background-color: $blue !important;
    }

    &.label-status-cancelled {
      background-color: $red !important;
    }

    &.label-status-primary {
      background-color: $blue !important;
    }

    &.label-status-danger {
      background-color: $red !important;
    }
  }

  .dot {
    &.dot-darkprimary {
      background-color: $darkblue !important;
    }

    &.dot-primary {
      background-color: $blue !important;
    }

    &.dot-danger {
      background-color: $red !important;
    }
  }

  .user-avatar-empty {
    border: 1px dashed $darkblue !important;
  }

  .user-avatar-initial {
    background: $darkblue !important;
  }

  .btn-link {
    color: $darkblue !important;

    &:hover,
    &:active,
    &:focus {
      color: $darkblue !important;
    }
  }

  .btn-close {
    color: $red !important;

    .feather {
      fill: $red !important;

      circle {
        stroke: $red !important;
      }
    }
  }

  .btn-delete {
    .feather {
      fill: $red !important;
    }
  }

  .btn-darkprimary:not(:disabled):not(.disabled):active,
  .btn-darkprimary:not(:disabled):not(.disabled).active,
  .show > .btn-darkprimary.dropdown-toggle {
    background-color: $darkblue !important;
    border-color: $darkblue !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: $blue !important;
    border-color: $blue !important;
  }

  .btn-icon-primary {
    color: $blue !important;
  }

  .btn-icon-primary:hover {
    color: $blue !important;
  }

  .btn-icon-danger {
    color: $red !important;
  }

  .btn-icon-danger:hover {
    color: $red !important;
  }

  .btn-hover {
    &.btn-icon {
      &:hover {
        color: $blue !important;
      }
    }
  }

  .container-working-hours {
    .dot-primary {
      background-color: $primary_workinghours !important;
    }

    .text-primary {
      color: $primary_workinghours !important;
    }

    .bg-primary {
      background-color: $primary_workinghours !important;
    }
  }

  .progress-bar {
    &.bg-gradient {
      background: $gradient !important;
    }
  }

  .auth-loader-logo-wrapp {
    background: $loader_auth !important;
  }

  .lds-ellipsis-api div {
    background: $loader_auth !important;
  }

  .switcher-wrapper {
    .selected-switcher {
      background-color: $darkblue !important;
    }
  }

  .header-service {
    background-color: $darkblue !important;
  }

  .manage-package-membership {
    .pill-package-menu-list {
      li {
        .btn.active {
          background: $darkblue !important;
        }
      }
    }

    .package-name {
      background: $darkblue !important;
    }
  }

  .service-package-container {
    .header-service {
      background-color: $darkblue !important;
    }
  }

  .color-palette-wrapper {
    .palette-list {
      .color-blue {
        fill: $blue !important;
        stroke: $blue !important;

        &.active {
          stroke: $black !important;
        }
      }

      .color-red {
        &.active {
          stroke: $black !important;
        }
      }

      .color-green {
        &.active {
          stroke: $black !important;
        }
      }

      .color-yellow {
        &.active {
          stroke: $black !important;
        }
      }

      .color-pink {
        &.active {
          stroke: $black !important;
        }
      }

      .color-purple {
        &.active {
          stroke: $black !important;
        }
      }
    }
  }

  .comment-item {
    .comment-wrapp {
      .btn-resolve {
        &:hover {
          color: $blue !important;

          .circle-icon {
            fill: $blue !important;

            circle {
              stroke: $blue !important;
              fill: $blue !important;
            }
          }
        }
      }

      .btn-more {
        &:hover {
          color: $blue !important;
        }
      }

      .menu-comment {
        .btn {
          &:hover {
            color: $blue !important;
          }
        }

        .btn-delete {
          &:hover {
            color: $red !important;
          }
        }
      }
    }
  }

  .header-annotation-wrapper {
    .btn-header-annotation {
      &.active {
        background: $blue !important;
      }

      &:hover {
        background: $blue !important;
      }
    }
  }

  .toolbox-canvas-wrapper {
    background-color: $black !important;

    .btn-toolbox {
      &:hover,
      &.active {
        background-color: $blue !important;
      }
    }
  }

  .panel-versions-wrapper {
    background-color: $black !important;

    ul {
      li {
        &.active {
          .preview-version-file {
            border: 1px solid $blue !important;
          }
        }
      }
    }
  }

  .dropfile-container {
    border: 1px dashed $blue !important;

    .warning-wrapper {
      .input-validation-msg {
        background-color: $red !important;
      }
    }
  }

  .radio-button-wrapper {
    .input-validation-msg {
      background-color: $red !important;
    }

    .radio-input-container {
      &:before {
        border: 1px solid $darkblue !important;
      }

      .radio-input {
        &:before {
          background-color: $darkblue !important;
          border: 1px solid $darkblue !important;
        }
      }
    }
  }

  .btn-top-nav.active {
    background-color: $blue !important;
  }

  .badge-info {
    background-color: $blue !important;
  }

  .btn-notification:hover {
    color: $blue !important;
  }

  .notification-main {
    .notification-content {
      .text-ready-to-go {
        color: $blue !important;
      }

      .text-queue {
        color: $orange !important;
      }

      .text-draft {
        color: $dark !important;
      }

      .text-in-progress {
        color: $blue !important;
      }

      .text-in-review {
        color: $blue !important;
      }

      .text-on-hold {
        color: $orange !important;
      }

      .text-approved {
        color: $blue !important;
      }

      .text-cancelled {
        color: $red !important;
      }
    }
  }

  .btn-read-notif .feather {
    fill: $blue !important;
  }

  .menu-user a:hover {
    color: $blue !important;
  }

  .order-filter-preset-wrapper {
    ul.order-preset-list {
      li {
        &.active,
        &:hover {
          color: $darkblue !important;
        }
      }
    }
  }

  .popover-service {
    border: 1px solid $blue !important;
  }

  .popover-service.bs-popover-right > .arrow::before,
  .popover-service.bs-popover-auto[x-placement^='right'] > .arrow::before {
    border-right-color: $blue !important;
  }

  .popover-service.bs-popover-left > .arrow::before,
  .popover-service.bs-popover-auto[x-placement^='left'] > .arrow::before {
    border-left-color: $blue !important;
  }

  .text-primary.btn-plus-tooltip {
    color: $blue !important;
  }

  .text-danger.btn-plus-tooltip {
    color: $red !important;
  }

  .popover-upgrade {
    border-color: $blue !important;
  }

  .table-listing-wrapper {
    .cell-actions {
      .btn-icon {
        &:hover {
          color: $blue !important;
        }
      }

      .btn-trash {
        &:hover {
          color: $red !important;
        }
      }
    }
  }

  .payment-list-wrapper {
    .radio-input-container {
      &:before {
        border: 1px solid $blue !important;
      }

      .radio-input {
        &:before {
          background-color: $blue !important;
          border: 1px solid $blue !important;
        }
      }
    }
  }

  .card-item {
    .card-item-status {
      &.status-warning {
        background-color: $orange !important;
      }

      &.status-primary {
        background-color: $darkblue !important;
      }

      &.status-danger {
        background-color: $red !important;
      }
    }

    .operator-unassigned {
      border: 1px dashed $dark !important;
    }
  }

  .priority-low {
    color: $darkblue !important;
  }

  .priority-high {
    color: $red !important;
  }

  .card-item-active {
    .dot {
      background-color: $darkblue !important;
    }

    .title-status {
      color: $darkblue !important;
    }

    .time-order {
      .queue-time {
        color: $darkblue !important;
      }

      .work-time {
        color: $red !important;
      }
    }
  }

  .card-item-inprogress {
    .dot {
      background-color: $darkblue !important;
    }

    .title-status {
      color: $darkblue !important;
    }

    .time-order {
      .queue-time {
        color: $red !important;
      }

      .work-time {
        color: $darkblue !important;
      }
    }
  }

  .card-item-inreview {
    .dot {
      background-color: $darkblue !important;
    }

    .title-status {
      color: $darkblue !important;
    }

    .time-order {
      .queue-time {
        color: $red !important;
      }

      .work-time {
        color: $red !important;
      }
    }
  }

  .card-item-queue {
    .dot {
      background-color: $orange !important;
    }

    .title-status {
      color: $orange !important;
    }
  }

  .card-item-draft {
    border: 1px dashed $dark !important;

    .dot {
      background-color: $dark !important;
    }

    .title-status {
      color: $dark !important;
    }
  }

  .card-item-onhold {
    border: 1px dashed $orange !important;

    .dot {
      background-color: $orange !important;
    }

    .title-status {
      color: $orange !important;
    }

    .reason,
    .updated-by {
      color: $orange !important;
    }
  }

  .card-item-approved {
    border: 1px dashed $darkblue !important;

    .dot {
      background-color: $darkblue !important;
    }

    .title-status {
      color: $darkblue !important;
    }

    .date-information {
      color: $darkblue !important;
    }

    .time-order {
      color: $darkblue !important;

      .queue-time {
        color: $darkblue !important;
      }

      .work-time {
        color: $darkblue !important;
      }
    }
  }

  .card-item-cancelled {
    border: 1px dashed $red !important;

    .dot {
      background-color: $red !important;
    }

    .title-status {
      color: $red !important;
    }

    .reason,
    .updated-by {
      color: $red !important;
    }
  }

  .card-placeholder {
    border: 1px dashed $darkblue !important;
    color: $darkblue !important;
  }

  .invoices-wrapper {
    .cell-table-tm-7 {
      .btn-icon {
        &:hover {
          color: $blue !important;
        }
      }

      .btn-trash {
        &:hover {
          color: $red !important;
        }
      }
    }
  }

  .popover-service {
    border: 1px solid $blue !important;
  }

  .popover-service.bs-popover-right > .arrow::before,
  .popover-service.bs-popover-auto[x-placement^='right'] > .arrow::before {
    border-right-color: $blue !important;
  }

  .popover-service.bs-popover-left > .arrow::before,
  .popover-service.bs-popover-auto[x-placement^='left'] > .arrow::before {
    border-left-color: $blue !important;
  }

  .user-comment .draftJsMentionPlugin__mention__29BEd,
  .user-comment .draftJsMentionPlugin__mention__29BEd:visited {
    color: $blue !important;
  }

  .btn-comment-action {
    &:hover {
      color: $blue !important;
    }

    &.btn-comment-action-delete {
      &:hover {
        color: $red !important;
      }
    }
  }

  .comment-body-wrapper {
    .mentioned-user {
      background: $darkblue !important;
    }
  }

  .section-files {
    .printReady-indicator {
      background-color: $blue !important;
    }
  }

  .img-prev-order-detail {
    .total-topic {
      background-color: $darkblue !important;

      &::before {
        border-top-color: $darkblue !important;
      }
    }
  }

  .dropdown-item-btn.active,
  .dropdown-item-btn:active {
    color: $blue !important;
  }

  .order-detail-container {
    .worktime.text-primary {
      color: $darkblue !important;
    }
  }

  .btn-follow {
    &.btn-follow-active {
      color: $darkblue !important;

      .feather {
        fill: $darkblue !important;
      }

      &:hover,
      &:focus {
        color: $darkblue !important;

        .feather {
          fill: $darkblue !important;
        }
      }
    }
  }

  .section-text-editor {
    .mentioned-user {
      background: $darkblue !important;
    }
  }

  .draftJsMentionPlugin__mention__29BEd,
  .draftJsMentionPlugin__mention__29BEd:visited {
    color: $blue !important;
  }

  .draftJsMentionPlugin__mention__29BEd:hover,
  .draftJsMentionPlugin__mention__29BEd:focus {
    color: $blue !important;
  }

  .modal-order-detail-transition {
    .modal-order-detail {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .order-detail-container {
              .section-body {
                .operator-options {
                  &:hover {
                    background-color: $blue !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .team-member-wrapper {
    .cell-table-tm-action {
      .btn-icon {
        &:hover {
          color: $blue !important;
        }
      }

      .btn-trash {
        &:hover {
          color: $red !important;
        }
      }
    }
  }

  .text-primary {
    color: $blue !important;
  }

  .text-darkprimary {
    color: $darkblue !important;
  }

  .btn-primary {
    background-color: $blue !important;
    border-color: $blue !important;

    &:hover {
      background-color: $blue !important;
      border-color: $blue !important;
    }
  }

  .btn-darkprimary {
    background-color: $darkblue !important;
    border-color: $darkblue !important;

    &:hover {
      background-color: $darkblue !important;
      border-color: $darkblue !important;
    }
  }
  
  &:disabled {
    background-color: $gray !important;
    border-color: $gray !important;
  }

  .section-header {
    .react-select {
      div[class$='control'] {
        background-color: $darkblue !important;
      }
    }
  }

  .create-order-container {
    .btn-draft {
      background-color: transparent;
      color: $darkblue !important;
      border: 1.5px dashed $darkblue !important;

      &:hover,
      &:focus {
        background-color: transparent;
        color: $darkblue !important;
        border: 1.5px dashed $darkblue !important;
      }
    }
  }

  .selected-action-item button:disabled {
    opacity: 1;
    color: #c3c3c3 !important;
  }

  .btn-icon-darkprimary {
    color: $darkblue !important;
  }

  .btn-icon-darkprimary:hover {
    color: $darkblue !important;
  }
}
