.header-wrapper {
  padding: 16px 16px 0 0;

  .btn-outlined {
    color: #009fb5;
    background: none;
    border: 1px solid #009fb5;

    &:hover {
      color: #009fb5;
      background: none;
      border: 1px solid #009fb5;
    }
  }
}

.header-toolbar {
  padding: 16px;
  border-radius: 10px;
  height: 80px;
  background-color: #efefef;
}

.navbar-dark .navbar-toggler {
  border: none;
}
