@import '../../scss/stylesngine.scss';
$primary: $blue;

.main-wrapper {
  background-color: #efefef;
  line-height: 23px;
  overflow-y: scroll;
  height: 100%;
  width: 100vw;

  .section-header {
    .left-side {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .title-text {
        font-size: 20px;
        font-weight: 700;
      }
      .due-date-text {
        font-size: 12px;
        font-weight: 700;
        color: #a9a9a9;
      }
      .rfq-title-quotation {
        flex-basis: 100%;
      }
    }
  }

  .section-body {
    margin-block: 24px;
    .details-col {
      .address-row {
        .sender-col {
          font-weight: bold;
          line-height: 16px;
        }

        .recipient-col {
          .left-side {
            color: #aaaaaa;
            font-weight: lighter;
            font-size: 12px;
          }

          .right-side {
            margin-top: 3px;
            font-weight: bold;
            line-height: 16px;
            margin-bottom: 8px;
          }
        }

        .add-address {
          color: $primary;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          margin-block: 16px;
          padding: 32px;
          border: 2px dotted $primary;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      .detail-row {
        margin-block: 24px;

        .title-col {
          color: #4d5256;
          font-size: 16px;
          font-weight: 700;
        }
        .value-col {
          font-size: 12px;
          font-weight: 600;

          .left-side {
            font-weight: 400;
            color: #aaaaaa;
          }
          .right-side {
            word-wrap: break-word;
          }
          .show-more {
            cursor: pointer;
            margin-block: 8px;
          }
        }
      }

      .price-row {
        margin-block: 24px;

        .title-col {
          color: #4d5256;
          font-size: 16px;
          font-weight: 700;
        }
        .no-price,
        .add-price.disabled {
          color: #aaaaaa;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          padding: 32px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          cursor: default;
        }
        .add-price {
          color: $primary;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          padding: 32px;
          border: 2px dotted $primary;
          border-radius: 8px;
          cursor: pointer;
        }
        .price-col {
          font-size: 12px;
          font-weight: 600;
          .left-side {
            font-weight: 400;
            color: #aaaaaa;
          }

          .total-row {
            align-items: baseline;
            margin-top: 16px;
            margin-bottom: 8px;
            .total-price {
              font-size: 24px;
            }
          }

          .note-row {
            line-height: 16px;
            font-weight: 400;
            margin-bottom: 16px;
          }
        }

        .attachments {
          &:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #ddd;
          }
        }
      }

      .attachment-row {
        margin-block: 24px;

        .title-col {
          color: #4d5256;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .underline {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .dotted-border {
    border: 1px dashed #64c8e7;
    min-height: 10rem;
    width: 100%;
    border-radius: 5px;
    position: relative;

    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10rem;
    }

    .warning-wrapper {
      .input-validation-msg {
        position: absolute;
        top: 3px;
        right: 3px;
        padding: 5px 8px;
        background-color: #eb0045;
        color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .comments-and-version-section {
    position: relative;
    border-radius: 16px;
    height: 100%;

    .nav-tabs {
      border-bottom: 0;
      font-weight: 900;

      .nav-link {
        border: 0;
        background-color: #fff;
        font-weight: 700;
        color: #d9dee2;
      }

      .nav-link.active {
        border-bottom: 1px solid black;
        color: black;
        font-weight: 700;
      }

      .nav-link:hover,
      .nav-link:focus {
        border-bottom: 1px solid blue;
      }
    }

    .tab-content {
      height: 70vh;

      .scrollable-body {
        width: 100%;
        height: 80%;
        align-self: baseline;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
      }
      .no-history {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sticky-comment-input {
        position: absolute;
        bottom: 0;
        width: 100%;

        .comment-input-container {
          padding: 16px;
          border-top: 3px solid #f5f5f5;
          // background-color: #f5f5f5;

          .rfq-comment-form {
            border: 1px solid #d9dee2;
            border-radius: 5px;
            margin-right: 5px;
            padding: 2px;
            outline: none;
            background-color: #fff;
          }

          .rfq-form {
            display: flex;
            flex-direction: row;
            .avatar-col {
              width: 48px;
              .avatar {
                width: 32px;
                height: 32px;
                border-radius: 100px;
              }
              .empty-avatar {
                width: 32px;
                height: 32px;
                border-radius: 100px;
                border: 1px dashed grey;
              }
            }
            .form-col {
              display: flex;
              flex-direction: column;
              width: 100%;
              .input {
                border: 1px solid lightgrey;
                border-radius: 4px;
                padding: 4px 12px;
              }
              .button-row {
                display: flex;
                flex-direction: row-reverse;
                margin-top: 16px;
              }
            }
          }
        }
      }
      .version-row {
        padding: 4px 8px;
        margin: 8px 0px;
        border: 1px solid #d9dee2;
        border-radius: 8px;
        font-weight: bolder;
        &.active {
          border: 2px solid $primary;
        }
      }
    }
  }
}

.shipping-edit-icon {
  margin-left: 8px;
  color: darkblue;
  cursor: pointer;
}

.price-edit-icon {
  color: grey;
  cursor: pointer;
}

.comment-row-container,
.history-row-container {
  display: flex;
  margin-block: 16px;
  .avatar-col {
    width: 32px;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 100px;
    }
    .empty-avatar {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      border: 1px dashed grey;
    }
  }
  .text-col {
    width: 100%;
    margin-left: 8px;
    .title-row {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-bottom: 4px;
      font-weight: 700;
      .time {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: grey;
        line-height: 16px;
        margin-top: 4px;
      }
      .hour-timezone {
        font-size: 10px;
        font-weight: 100;
      }
    }
    .content-row {
      word-wrap: break-word;
    }
  }
}

.history-row-container {
  .text-col {
    margin-left: 0;

    .title-row {
      .time {
        width: 200px;
      }
    }
  }
}
