@import '../../scss/theme';

.quotation-kanban-container {
  display: flex;
  height: 100%;

  .column-header .text-14 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

a.quotation-link {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.quotation-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  // overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: border 0.2s ease-in;
  user-select: none;

  .bubble-message {
    position: absolute;
    right: -5px;
    top: -5px;
    background-color: red;
    z-index: 99;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .psp-list {
    border-left: 1px solid $darkblue;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  &.status-on-quote,
  &.status-in-review {
    .dot-12 {
      background-color: $darkblue;
    }
    .status-label {
      color: $darkblue;
    }
  }

  &.status-pending {
    background-color: transparent;
    border: 1px dashed $black;

    .dot-12 {
      background-color: $black;
    }
    .status-label {
      color: $black;
    }
    .psp-list {
      border-color: $black;
    }
  }

  &.status-cancelled {
    background-color: transparent;
    border: 1px dashed $red;

    .title-status-card {
      margin: -15px -15px 15px -15px;
      background-color: $red;
      padding: 5px 10px;
      color: #fff;
      font-style: italic;
    }
    .psp-list {
      border-color: $red;
    }
  }

  &.status-accepted {
    background-color: transparent;
    border: 1px dashed $darkblue;
  }

  .quotation-item-container {
    padding: 15px;

    &.status-primary {
      background-color: $darkblue;
      color: #fff;
    }

    &.status-danger {
      background-color: $red;
      color: #fff;
    }

    &.status-frozen {
      background-color: $error_notification;
      color: #fff;
    }
  }

  .title-status-card {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .quotation-content-info {
    margin-right: 0px;
    margin-left: 15px;
  }

  .dot {
    width: 14px;
    height: 14px;
  }

  .title-status-card {
    font-size: 12px;
    height: 24px;
  }

  .time-order {
    color: #a9a9a9;

    .queue-time {
      color: #a9a9a9;
    }

    .work-time {
      color: #a9a9a9;
    }
  }

  .reason,
  .updated-by {
    display: none;
  }

  .operator-unassigned {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px dashed $dark;
    display: flex;
    background-color: transparent;
  }

  .operator-assigned {
    width: 30px;
    height: 30px;
  }

  .preview-attachment-wrapp {
    max-height: 320px;
    margin-bottom: 15px;
    overflow: hidden;

    .preview-attachment {
      display: block;
      position: relative;
      width: 100%;
      object-fit: fill;
      height: 100%;
    }
  }
}

.priority-order {
  border-radius: 5px;
  color: #fff;
  background-color: $darkblue;
  font-weight: 600;
  padding: 2px 6px;

  &.priority-order-danger {
    background-color: $red;
  }

  &.priority-order-warning {
    background-color: $orange;
  }
}

.btn-toggle-previewimage-card {
  color: #c3c3c3;

  &:focus,
  &:hover {
    color: #c3c3c3;
  }
}

.package-name {
  background-color: $black;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  padding: 3px 6px;
  font-weight: bold;
}

.title-quotation-theme {
  .tippy-content {
    padding: 0px 3px;
    font-size: 12px;
  }
}

.quotation-item-active {
  .dot {
    background-color: $darkblue;
  }

  .title-status {
    color: $darkblue;
  }

  .time-order {
    color: #4d5256;

    .queue-time {
      font-weight: bold;
      color: $darkblue;
    }

    .work-time {
      font-weight: bold;
      color: $red;
    }
  }
}

.quotation-item-inprogress {
  .dot {
    background-color: $darkblue;
  }

  .title-status {
    color: $darkblue;
  }

  .time-order {
    color: #4d5256;

    .queue-time {
      font-weight: bold;
      color: $red;
    }

    .work-time {
      font-weight: bold;
      color: $darkblue;
    }
  }
}

.quotation-item-inreview {
  .dot {
    background-color: $darkblue;
  }

  .title-status {
    color: $darkblue;
  }

  .time-order {
    color: #4d5256;

    .queue-time {
      font-weight: bold;
      color: $red;
    }

    .work-time {
      font-weight: bold;
      color: $red;
    }
  }
}

.quotation-item-queue {
  .dot {
    background-color: $orange;
  }

  .title-status {
    color: $orange;
  }
}

.quotation-item-draft {
  background-color: #f1f1f1;
  border: 1px dashed $dark;

  .date-information {
    color: #a9a9a9;

    // .priority-info {
    //   color: #a9a9a9 !important;
    // }
  }

  .dot {
    background-color: $dark;
  }

  .title-status {
    color: $dark;
  }
}

.quotation-item-cm-review {
  background-color: #f1f1f1;
  border: 1px dashed #9d57e9;

  .quotation-item-status {
    background-color: #9d57e9 !important;
    color: #fff;
  }
}

.quotation-item-onhold {
  background-color: #f1f1f1;
  border: 1px dashed $orange;

  .dot {
    background-color: $orange;
  }

  .title-status {
    color: $orange;
  }

  .date-information,
  .time-order {
    display: none;
  }

  .reason,
  .updated-by {
    display: block;
    color: $orange;
  }
}

.quotation-item-approved {
  background-color: #f1f1f1;
  border: 1px dashed $darkblue;

  .dot {
    background-color: $darkblue;
  }

  .title-status {
    color: $darkblue;
  }

  .date-information {
    color: $darkblue;
  }

  .time-order {
    color: $darkblue;

    .queue-time {
      font-weight: bold;
      color: $darkblue;
    }

    .work-time {
      font-weight: bold;
      color: $darkblue;
    }
  }
}

.quotation-item-cancelled {
  background-color: #f1f1f1;
  border: 1px dashed $red;

  .dot {
    background-color: $red;
  }

  .title-status {
    color: $red;
  }

  .date-information,
  .time-order {
    display: none;
  }

  .reason,
  .updated-by {
    display: block;
    color: $red;
  }
}

.load-more {
  display: flex;

  .load-more-button {
    width: 100%;
  }
}
