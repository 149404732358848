.container-add-price {
  height: 530px;
  .layout {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .body-row {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;

      .dropzone {
        padding: 15px;
        border: 1px dashed #009fb5;
        text-align: center;
        border-radius: 10px;

        .label-file {
          font-weight: 800;
          margin-bottom: 2px;
          .choose-files {
            text-decoration: underline;
            color: #009fb5;
            cursor: pointer;
            display: inline-block;
          }
        }
        .supported-files {
          margin-bottom: 2px;
          color: #aaa;
        }
        .max-file-size {
          color: #eb0045;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    .attachment {
      display: flex;
      align-items: center;
      gap: 8px;

      .input {
        display: none;
      }

      .icon {
        cursor: pointer;
      }
    }

    .footer-row {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
